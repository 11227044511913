/// <reference path="../../../index.d.ts" />
import { useMsal } from '@azure/msal-react';
import { Box, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import React from 'react';
import { Col, Row, Visible, setConfiguration } from 'react-grid-system';
import { useGridSystem } from 'react-helpers';
import { SubmitButton } from 'react-helpers/src/components/forms';
import useNotistack from 'react-helpers/src/hooks/useNotistack';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import VitlIcon from '../../assets/icons/vitl-icon.png';
import AerialNeighborhoodImage from '../../assets/images/aerial-neighborhood.jpg';
import { Tokens, login, loginRequest } from '../../services/auth.service';

export type LoginForm = {
    type: string;
    token: string;
};

const Login = () => {
    setConfiguration({ gutterWidth: 0 });
    const { instance } = useMsal();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginForm>();
    const { showAxiosError } = useNotistack();
    const navigate = useNavigate();
    const { sm } = useGridSystem();

    const { mutate: loginMutation, isLoading } = useMutation((data: LoginForm) => login(data), {
        onError: (error: AxiosError) => {
            showAxiosError(error);
        },
        onSuccess: (response: Tokens) => {
            localStorage.setItem('token', response.accessToken);
            localStorage.setItem('refreshToken', response.refreshToken);
            if (window.location.search.includes('referrer')) {
                window.location.href = `${window.location.search.split('=')[1]}?token=${
                    response.accessToken
                }&refreshToken=${response.refreshToken}`;
            } else navigate('/');
        },
    });

    const getStyles = () => {
        if (sm) {
            return {
                marginTop: '30%',
                padding: '36px',
            };
        }
        return {
            marginTop: '50%',
            padding: '36px',
        };
    };

    const handleLogin = (loginType: 'popup' | 'redirect') => {
        if (loginType === 'popup') {
            instance
                .loginPopup(loginRequest)
                .then((a) =>
                    loginMutation({
                        type: 'microsoft',
                        token: a.accessToken,
                    }),
                )
                .catch((e) => {
                    console.log(e);
                });
        } else if (loginType === 'redirect') {
            instance.loginRedirect(loginRequest).catch((e) => {
                console.log(e);
            });
        }
    };

    return (
        <Row>
            <Col lg={5} xl={4}>
                <Box display="flex" alignItems="flex-start" flexDirection="column" sx={getStyles()}>
                    <Box my={3} sx={{ width: 1 }}>
                        <Box mb={4} display="flex" alignItems="center">
                            <img src={VitlIcon} width={150} />
                            <Typography className="font-skrappa" variant="h2" fontWeight={700}>
                                VITL HOME
                            </Typography>
                        </Box>
                        <Box mt={1}>
                            <SubmitButton
                                onClick={() => {
                                    handleLogin('popup');
                                }}
                                isLoading={isLoading}
                            >
                                <img
                                    style={{ width: 24, marginRight: 16 }}
                                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/0e/Microsoft_365_%282022%29.svg/512px-Microsoft_365_%282022%29.svg.png"
                                />
                                Login with VITL Microsoft
                            </SubmitButton>
                        </Box>
                    </Box>
                </Box>
            </Col>
            <Col lg={7} xl={8}>
                <Visible lg xl xxl>
                    <Box
                        sx={{ width: '100%', height: '100vh', backgroundImage: `url(${AerialNeighborhoodImage})` }}
                    ></Box>
                </Visible>
            </Col>
        </Row>
    );
};

export default Login;
